<!--
 * @Author: your name
 * @Date: 2021-01-28 18:44:37
 * @LastEditTime: 2021-05-19 14:28:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\views\ending.vue
-->
<template>
  <div>
    <p class="msg">
      <span class="wan" v-if="num == 1">分享成功</span>
      <span class="wan" v-else>分享拒绝</span>
    </p>
    <div>
      <img v-if="num == 1" src="../assets/kaixincopy.png" alt="" />
      <img v-else src="../assets/nanguo-.png" alt="" />
    </div>
    <div class="box" v-if="num == 1">
      <p>分享成功</p>
      <p>萝卜猎手祝您前程似锦</p>
      <div @click="tochome" class="tohome">去首页逛逛</div>
    </div>
    <div class="box" v-else>
      <p>已拒绝分享</p>
      <p>萝卜猎手祝您前程似锦</p>
      <div @click="tochome" class="tohome">去首页逛逛</div>
    </div>
    
    <video
      autoplay="autoplay"
      muted
      src="http://1252492711.vod2.myqcloud.com/958d2b29vodtransgzp1252492711/11ff48575285890797347537027/v.f19999.mp4"
    ></video>
  </div>
</template>

<script>
import base from "../util/base"
export default {
  data() {
    return {
      num: "",
    };
  },
  created() {
    console.log(this.$router.currentRoute.params);
    this.num = this.$router.currentRoute.params.num;
    setTimeout(()=>{
      window.location = base.urlc;
    },3000)
  },
  destroyed() {
    localStorage.removeItem("to");
  },
  methods:{
    tochome(){
      window.location = base.urlc;
    }
  }
};
</script>

<style scoped lang='less'>
.tohome{
  text-align: center;
  font-size: .3rem;
  color: #409EFF;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 3.07rem;
}
img {
  position: relative;
  left: 3.15rem;
  top: 0.39rem;
  // transform: translateX(-50%);
  text-align: center;
  width: 1.2rem;
  height: 1.2rem;
}
.box {
  position: absolute;
  top: 3rem;
  left: 1.75rem;
  width: 4rem;
  height: 0.96rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.48rem;
  p {
    text-align: center;
  }
}
video {
  position: absolute;
  top: 4.5rem;
  left: 0.3rem;
  width: 6.9rem;

  border-radius: 0.26rem 0.26rem 0.26rem 0.26rem;
}
</style>